@font-face {
  font-family: "Poppins Bold";
  src: url("./assets/fonts/Poppins-Bold.ttf")
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf")
}

@font-face {
  font-family: "Poppins Light";
  src: url("./assets/fonts/Poppins-Light.ttf")
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  /* font-family: 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section_title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 4px;
    background: #000;
    position: absolute;
    left: 0;
    bottom: -10px;
    margin-bottom: 10px;
  }
}